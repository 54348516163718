import React, { useState } from 'react';
import ProjectsTable from './ProjectsTable';
import {
	HeaderButton,
	HeaderContainer,
	HeaderTitle,
} from '../common/layout/PageHeader';
import { useNavigate } from 'react-router-dom';
import Button from '../common/buttons/Button';
import ProjectCreationModal from './creation/ProjectCreationModal';

export default function ProjectsListScreen() {
	const navigate = useNavigate();

	const [projectCreationModalOpen, setProjectCreationModalOpen] =
		useState(false);

	return (
		<div>
			<ProjectCreationModal
				open={projectCreationModalOpen}
				handleClose={() => setProjectCreationModalOpen(false)}
			/>
			<HeaderContainer>
				<HeaderTitle>Projects</HeaderTitle>
				<HeaderButton>
					<Button
						onClick={() => setProjectCreationModalOpen(true)}
						variant={'outlined'}
					>
						Create Project
					</Button>
				</HeaderButton>
			</HeaderContainer>
			<ProjectsTable />
		</div>
	);
}
