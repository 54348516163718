import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useNavigate,
	Navigate,
	useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoginScreen from './LoginScreen';
import { AppDispatch, RootState } from '../data/store';
import ProjectsRouter from './projects/ProjectsRouter';
import HomeDashboardScreen from './dashboard/HomeDashboardScreen';
import ClientsRouter from './clients/ClientsRouter';
import AlertBanner from './common/AlertBanner';
import { logout } from '../data/stores/authStore';
import OAuth2CallbackScreen from './OAuth2CallbackScreen';
import MiniDrawer from './navigation/Drawer';
import ConfigurationScreen from './configuration/ConfigurationScreen';
import UsersListScreen from './users/UsersListScreen';
import SettingsScreen from './settings/SettingsScreen';
import CompanyScreen from './company/CompanyScreen';
import ProfileScreen from './profile/ProfileScreen';
import PolicyScreen from './policy/PolicyScreen';
import HelpScreen from './help/HelpScreen';
import { fetchSelf } from '../data/thunks/userThunk';

function AuthRoutes() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const location = useLocation();
	const authState = useSelector((state: RootState) => state.authState);

	useEffect(() => {
		if (!authState.astroToken || !authState.user) {
			// /oauth2callback means we are in the middle of the OAuth2 flow
			if (location.pathname !== '/oauth2callback') {
				navigate('/login');
			}
		}
	}, [authState, navigate, location.pathname]);

	useEffect(() => {
		dispatch(fetchSelf());
	}, []);

	const showMiniDrawer = !['/login', '/oauth2callback'].includes(
		location.pathname
	);

	return (
		<main>
			{showMiniDrawer ? (
				<MiniDrawer>
					<Routes>
						{/* <Route path="/" element={<Navigate to="/projects" replace />} /> */}
						<Route path="/" element={<HomeDashboardScreen />} />
						<Route
							path="/projects/*"
							element={<ProjectsRouter />}
						/>
						<Route path="/clients/*" element={<ClientsRouter />} />
						<Route path="/users/*" element={<UsersListScreen />} />
						<Route
							path="/configuration"
							element={<ConfigurationScreen />}
						/>
						<Route path="/settings" element={<SettingsScreen />} />
						<Route path="/company" element={<CompanyScreen />} />
						<Route path="/profile" element={<ProfileScreen />} />
						<Route path="/policy" element={<PolicyScreen />} />
						<Route path="/help" element={<HelpScreen />} />
						{/* <Route path="/login" element={<LoginScreen />} />
						<Route
							path="/oauth2callback"
							element={<OAuth2CallbackScreen />}
						/> */}
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</MiniDrawer>
			) : (
				<Routes>
					<Route path="/login" element={<LoginScreen />} />
					<Route
						path="/oauth2callback"
						element={<OAuth2CallbackScreen />}
					/>
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			)}
			<AlertBanner />
		</main>
	);
}

export default function AuthenticatedRouter() {
	return (
		<Router>
			<AuthRoutes />
		</Router>
	);
}
