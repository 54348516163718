import React, { useEffect } from 'react';
import styled from 'styled-components';

import Modal from '../common/Modal';
import { Controller, useForm } from 'react-hook-form';
import { User, UserRolesDDOptions } from '../../data/models/user';
import { Grid } from '@mui/material';
import Input from '../common/form/Input';
import ModalHeader from '../common/ModalHeader';
import Autocomplete from '../common/form/AutoComplete';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { updateUser } from '../../data/thunks/userThunk';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../data/store';
import Checkbox from '../common/form/Checkbox';

interface EditUserModalProps {
	open: boolean;
	handleClose: () => void;
	user?: User;
}
export default function EditUserModal(props: EditUserModalProps) {
	const { open, user, handleClose } = props;

	const dispatch = useDispatch<AppDispatch>();

	const defaultValues: Partial<User> = {
		firstName: '',
		lastName: '',
		role: '',
		alwaysAddToProjects: false,
	};

	const { handleSubmit, control, reset, formState } = useForm({
		defaultValues,
		mode: 'onBlur',
	});
	const { errors } = formState;

	const resetForm = (user?: User) => {
		reset({
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			role: user?.role || '',
			alwaysAddToProjects: user?.alwaysAddToProjects || false,
		});
	};

	useEffect(() => {
		resetForm(user);
	}, [user]);

	const handleOnSaveClick = (data: Partial<User>) => {
		if (user?.id) {
			console.log('data', data);
			dispatch(updateUser({ id: user.id, ...data }));
		}
		resetForm();
		handleClose();
	};

	const handleCancelCloseOnClick = () => {
		resetForm();
		handleClose();
	};

	if (!open || !user) return <></>;

	return (
		<Modal open={open} handleClose={handleCancelCloseOnClick}>
			<Container>
				<ModalHeader>Edit User</ModalHeader>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<Input
							id={'email'}
							label={'Email'}
							onChange={(e) => console.log('Do nothing')}
							value={user.email}
							fullWidth
							disabled
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<Controller
							name={'firstName'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									{...field}
									id={'firstName'}
									label={'First Name'}
									onChange={(e) => {
										e.target.value =
											e.target.value
												.charAt(0)
												.toUpperCase() +
											e.target.value.slice(1);
										field.onChange(e);
									}}
									fullWidth
									error={errors.firstName}
									helperText={errors.firstName?.message}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name={'lastName'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Input
									{...field}
									id={'lastName'}
									label={'Last Name'}
									fullWidth
									error={errors.lastName}
									helperText={errors.lastName?.message}
									onChange={(e) => {
										e.target.value =
											e.target.value
												.charAt(0)
												.toUpperCase() +
											e.target.value.slice(1);
										field.onChange(e);
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6} style={{ marginTop: 15 }}>
						<Controller
							name={'role'}
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Autocomplete
									{...field}
									id={'role'}
									label={'Role'}
									options={UserRolesDDOptions}
									onChange={(e, value) => {
										field.onChange(value?.id || '');
									}}
									error={!!errors.role}
									helperText={errors.role?.message}
								/>
							)}
						/>
					</Grid>
					<CenterGrid item xs={12} md={6}>
						<Controller
							name={'alwaysAddToProjects'}
							control={control}
							render={({ field }) => {
								return (
									<Checkbox
										size={'small'}
										checked={field.value}
										onChange={(e) => {
											field.onChange(e.target.value);
										}}
										onClick={(e) => e.stopPropagation()}
										items={[
											{
												id: 'alwaysAddToProjects',
												name: 'Always add to projects',
												checked: field.value,
												handleOnChange: () =>
													field.onChange(
														!field.value
													),
											},
										]}
									/>
								);
							}}
						/>
					</CenterGrid>
				</Grid>
				<ButtonContainer>
					<SaveCancelButtons
						handleSave={handleSubmit(handleOnSaveClick)}
						handleCancel={handleCancelCloseOnClick}
					/>
				</ButtonContainer>
			</Container>
		</Modal>
	);
}

const Container = styled.div`
	display: flex;
	justify-content: space-around;
	flex-direction: column;
`;

const ButtonContainer = styled.div`
	margin-top: 30px;
`;

const CenterGrid = styled(Grid)`
	align-items: center;
	justify-content: center;
	display: flex;
`;
