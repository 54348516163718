import React from 'react';
import Button from '../common/buttons/Button';
import { useNavigate } from 'react-router-dom';
import ScreenContainer from '../common/layout/ScreenContainer';
import CenterContainer from '../common/layout/CenterContainer';
import { styled } from 'styled-components';
import {
	ProjectsRecentlyCreated,
	ProjectsRecentlyArchived,
} from './ProjectsLog';
import { useSelector } from 'react-redux';
import { RootState } from '../../data/store';
import { isAdmin } from '../../utils/auth-utils';
import { HeaderTitle } from '../common/layout/PageHeader';
import QuickLinks from './QuickLinks';
import KeyLinks from './KeyLinks';
import { Grid } from '@mui/material';

export default function HomeDashboardScreen() {
	const { user } = useSelector((state: RootState) => state.authState);

	return (
		<ScreenContainer>
			<HeaderTitle>
				Welcome, {user?.firstName} {user?.lastName}
			</HeaderTitle>
			<Grid container spacing={3}>
				<Grid item xs={12} md={9}>
					<QuickLinks />
				</Grid>
				<Grid item xs={12} md={3} style={{ flex: '1 1 auto' }}>
					<KeyLinks />
				</Grid>
				<Grid item xs={12} md={6}>
					<ProjectsRecentlyCreated />
				</Grid>
				<Grid item xs={12} md={6}>
					<ProjectsRecentlyArchived />
				</Grid>
			</Grid>
		</ScreenContainer>
	);
}
