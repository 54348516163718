import React, { useState } from 'react';
import UsersTable from './UsersTable';
import {
	HeaderButton,
	HeaderContainer,
	HeaderTitle,
} from '../common/layout/PageHeader';
import AddUsersModal from './AddUsersModal';
import Button from '../common/buttons/Button';

export default function UsersListScreen() {
	const [addUsersModal, setAddUsersModal] = useState(false);

	return (
		<div>
			<AddUsersModal
				open={addUsersModal}
				handleClose={() => setAddUsersModal(false)}
			/>
			<HeaderContainer>
				<HeaderTitle>Astro Users</HeaderTitle>
				<HeaderButton>
					<Button
						onClick={() => setAddUsersModal(true)}
						variant={'outlined'}
					>
						Add User
					</Button>
				</HeaderButton>
			</HeaderContainer>
			<UsersTable />
		</div>
	);
}
