import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import GlobalStyles from '../theme/global-styles';
import { getTheme } from '../theme/theme';
import { RootState } from '../data/store';
import AuthenticatedRouter from '../components/AuthenticatedRouter';

const ThemeProvider: React.FC = () => {
	const themePreference = useSelector(
		(state: RootState) => state.authState.user?.preferences?.theme || 'dark'
	);

	let userThemePreference =
		themePreference === 'system'
			? window.matchMedia('(prefers-color-scheme: dark)').matches
				? 'dark'
				: 'light'
			: themePreference;

	const theme = getTheme(userThemePreference);

	return (
		<MuiThemeProvider theme={theme}>
			<SCThemeProvider theme={theme}>
				<>
					<GlobalStyles />
					<AuthenticatedRouter />
				</>
			</SCThemeProvider>
		</MuiThemeProvider>
	);
};

export default ThemeProvider;
