import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { Client } from '../data/models/client';
import { fetchClients } from '../services/client-service';

const MAX_CLIENTS = 999;

function useClientsList() {
	const [list, setList] = useState<Client[]>([]);

	useEffect(() => {
		const fetchClientsForDropdown = async () => {
			const clients = await fetchClients({
				searchText: '',
				order: 'created',
				direction: 'DESC',
				page: 0,
				take: MAX_CLIENTS,
				where: { status: 'ALL' },
			});
			setList(clients?.list || []);
		};
		fetchClientsForDropdown();
	}, []);

	const dropdownOptions = useMemo(() => {
		return sortBy(
			list.map((client: any) => ({
				id: client.id,
				display: client.name,
			})),
			['display']
		);
	}, [list]);

	return {
		list,
		dropdownOptions,
	};
}

export default useClientsList;
