import React, { forwardRef } from 'react';
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material';

interface AutocompleteProps {
	id?: string;
	options: AutocompleteOption[];
	label: string;
	placeholder?: string;
	value: any;
	onChange: any;
	getOptionLabel?: any;
	disabled?: boolean;
	multiple?: boolean;
	freeSolo?: boolean;
	filterOptions?: any;
	onInputChange?: any;
	helperText?: string;
	error?: boolean;
	required?: boolean;
	onBlur?: any;
	size?: 'small' | 'medium';
}
const Autocomplete = forwardRef((props: AutocompleteProps, ref) => {
	const {
		id = 'auto-complete-component',
		options,
		label,
		placeholder = 'Select',
		value,
		onChange,
		getOptionLabel = (option: string | AutocompleteOption) => {
			if (typeof option === 'string') {
				const matchingOption = options.find((opt) => opt.id === option);
				return matchingOption ? matchingOption.display : option;
			} else {
				return option.display;
			}
		},
		disabled,
		multiple = false,
		freeSolo = false,
		helperText,
		error = false,
		required = false,
		onBlur,
		size = 'small',
	} = props;

	const handleInputChange = (event: any, newValue: string) => {
		if (props.onInputChange) {
			props.onInputChange(event, newValue);
		}
	};

	const filterOptions = props.filterOptions
		? props.filterOptions
		: (options, { inputValue }) => {
				const match = new RegExp(inputValue, 'i');
				return options
					.filter((option) => match.test(getOptionLabel(option)))
					.sort((a, b) => {
						// Place exact matches at the top
						if (getOptionLabel(a) === inputValue) {
							return -1;
						}
						if (getOptionLabel(b) === inputValue) {
							return 1;
						}
						// For other matches, sort by their occurrence in the label
						return (
							getOptionLabel(a).indexOf(inputValue) -
							getOptionLabel(b).indexOf(inputValue)
						);
					});
		  };

	return (
		<MuiAutocomplete
			size={size}
			multiple={multiple}
			filterSelectedOptions
			id={id}
			options={options}
			getOptionLabel={getOptionLabel}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						{option.display}
					</li>
				);
			}}
			onReset={() => console.log('reset')}
			isOptionEqualToValue={(option, value) =>
				option.id === value.id ||
				option.id === value ||
				option.display === value
			}
			onChange={onChange}
			value={value || null} // null means this will always be a controlled component
			sx={{ width: '100%' }}
			disabled={disabled}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={placeholder}
					error={error}
					helperText={helperText}
					required={required}
				/>
			)}
			onInputChange={handleInputChange}
			freeSolo={freeSolo}
			filterOptions={filterOptions}
			onBlur={onBlur}
			autoHighlight
		/>
	);
});
export default Autocomplete;

export interface AutocompleteOption {
	id: string;
	display: string;
}
