import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
	asyncCallStart,
	asyncCallError,
	asyncCallDone,
	refreshClientSuccess,
	fetchClientsSuccess,
} from '../stores/clientStore';
import * as ClientService from '../../services/client-service';
import { showAlert } from '../stores/alertStore';
import { ClientsFilter } from '../models/client';

export const fetchClient =
	(id: string): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await ClientService.fetchClient(id);
			dispatch(refreshClientSuccess(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Client.',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

export const fetchClients =
	(filter: ClientsFilter): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await ClientService.fetchClients(filter);
			dispatch(fetchClientsSuccess(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Client',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};
