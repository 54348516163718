import React, { useEffect, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BusySpinner from '../../common/BusySpinner';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import { AppDispatch, RootState } from '../../../data/store';
import { fetchProject } from '../../../data/thunks/projectThunk';
import { ProjectStatuses } from '../../../data/models/project';
import dayjs from 'dayjs';
import { styled } from 'styled-components';
import Menu from '../../common/Menu';
import ProjectNameChangeModal from './ProjectNameChangeModal';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ProjectArchiveModal from './ProjectArchiveModal';
import ProjectUnarchiveModal from './ProjectUnarchiveModal';
import BusinessIcon from '@mui/icons-material/Business';
import ProjectChangeClient from './ProjectChangeClientModal';
import { SubHeader } from '../../common/layout/PageHeader';
import { Divider } from '@mui/material';
import GreenCheckIcon from '../../common/GreenCheckIcon';
import RedExclamationIcon from '../../common/RedExclamationIcon';
import ProjectStatusDisplay from '../ProjectStatusDisplay';
import GoogleDriveIcon from '../../../images/google-drive.png';
import GoogleChatIcon from '../../../images/google-chat.png';

export default function ProjectDetailsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const match = useMatch('projects/:projectId/*');
	const projectId = match?.params?.projectId;

	const [projectNameChangeModalOpen, setProjectNameChangeModalOpen] =
		useState(false);
	const [archiveProjectModal, setArchiveProjectModal] = useState(false);
	const [unarchiveProjectModal, setUnarchiveProjectModal] = useState(false);
	const [changeClientModal, setChangeClientModal] = useState(false);

	const { project } = useSelector((state: RootState) => state.projectState);

	useEffect(() => {
		if (projectId && projectId !== project.id) {
			dispatch(fetchProject(projectId));
		}
	}, [projectId, dispatch, project.id]);

	useEffect(() => {
		const checkStatuses = () => {
			if (
				project.status === ProjectStatuses.INITIALIZING.id ||
				project.status === ProjectStatuses.UPDATING.id
			) {
				return true;
			}

			const fileStatuses = project.projectInitializationFileStatuses;
			if (
				!fileStatuses ||
				project.projectInitializationStatus?.googleChatSpaceStatus ===
					'INITIALIZING'
			) {
				return true;
			}
			return Object.values(fileStatuses).some(
				(fileStatus: any) =>
					fileStatus.status === 'CREATED' ||
					fileStatus.status === 'INITIALIZING'
			);
		};

		if (checkStatuses() && projectId) {
			const interval = setInterval(() => {
				dispatch(fetchProject(projectId));
			}, 5000);

			return () => {
				clearInterval(interval);
			};
		}
	}, [
		project.status,
		projectId,
		dispatch,
		project.projectInitializationFileStatuses,
		project.projectInitializationStatus?.googleChatSpaceStatus,
	]);

	const menuItems = useMemo(() => {
		if (project.status === ProjectStatuses.ACTIVE.id) {
			const menuItems: any[] = [];
			menuItems.push({
				display: 'Update Project Name',
				onClick: () => setProjectNameChangeModalOpen(true),
				disabled:
					!project.projectInitializationStatus.googleFilesUpdated,
				icon: (
					<DriveFileRenameOutlineOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'Archive Project',
				onClick: () => setArchiveProjectModal(true),
				icon: (
					<ArchiveOutlinedIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			menuItems.push({
				display: 'Change Client',
				onClick: () => setChangeClientModal(true),
				icon: (
					<BusinessIcon
						color={'primary'}
						fontSize={'medium'}
						sx={{ marginRight: 1 }}
					/>
				),
			});
			return menuItems;
		} else if (project.status === ProjectStatuses.ARCHIVED.id) {
			return [
				{
					display: 'Unarchive Project',
					onClick: () => setUnarchiveProjectModal(true),
					icon: (
						<ArchiveOutlinedIcon
							color={'primary'}
							fontSize={'medium'}
							sx={{ marginRight: 1 }}
						/>
					),
				},
			];
		} else {
			return [];
		}
	}, [
		project.status,
		project.projectInitializationStatus.googleFilesUpdated,
	]);

	if (projectId !== project.id) {
		return <BusySpinner />;
	}

	return (
		<>
			<ScreenBusySpinner />
			<ProjectNameChangeModal
				project={project}
				open={projectNameChangeModalOpen}
				handleClose={() => setProjectNameChangeModalOpen(false)}
			/>
			<ProjectArchiveModal
				project={project}
				open={archiveProjectModal}
				handleClose={() => setArchiveProjectModal(false)}
			/>
			<ProjectUnarchiveModal
				project={project}
				open={unarchiveProjectModal}
				handleClose={() => setUnarchiveProjectModal(false)}
			/>
			<ProjectChangeClient
				project={project}
				open={changeClientModal}
				handleClose={() => setChangeClientModal(false)}
			/>
			<ScreenContainer>
				<HeaderContainer>
					<NamespaceContainer>
						<FlexContainer>
							<Namespace>{project.namespace}</Namespace>
							<ProjectStatusDisplay status={project.status} />
						</FlexContainer>
						<Menu
							title={'Actions'}
							menuItems={menuItems}
							variant={'outlined'}
							color={'primary'}
						/>
					</NamespaceContainer>
					<SubHeader>{project.client.name}</SubHeader>
					<SpaceBetweenContainer>
						<SubHeader>
							Created:{' '}
							{dayjs(project.created).format(
								'MMM D, YYYY, h:mm A'
							)}
						</SubHeader>
						<div>
							{project.driveId && project.folderId && (
								<img
									style={{
										height: 20,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleDriveIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://drive.google.com/drive/u/${project.driveId}/folders/${project.folderId}`,
											'_blank'
										)
									}
								/>
							)}
							{project.chatSpaceId && (
								<img
									style={{
										height: 20,
										marginLeft: 15,
										objectFit: 'cover',
										cursor: 'pointer',
									}}
									src={GoogleChatIcon}
									alt={'Google Drive'}
									onClick={() =>
										window.open(
											`https://mail.google.com/chat/u/2/#chat/space/${project.chatSpaceId}`,
											'_blank'
										)
									}
								/>
							)}
						</div>
					</SpaceBetweenContainer>
				</HeaderContainer>
				<FileStatuses project={project} />
			</ScreenContainer>
		</>
	);
}

const SpaceBetweenContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ScreenContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh; // take up the full viewport height
`;

const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

const Namespace = styled.h2`
	display: inline;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid ${({ theme }) => theme.palette.text.divider};
	padding-bottom: 15px;
	margin-bottom: 15px;
`;

const NamespaceContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const FileStatus = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	align-items: center;
`;

const Key = styled.span``;

const Value = styled.span`
	align-items: center;
	display: flex;
	margin-left: 1em;
`;

const FileStatuses = (props: any) => {
	const {
		project: {
			projectInitializationFileStatuses,
			projectInitializationStatus,
			projectUpdateStatus,
			status,
		},
	} = props;
	if (!projectInitializationFileStatuses) {
		return <div>Getting files ready for creation</div>;
	}

	if (status === 'UPDATING') {
		return <div>{projectUpdateStatus?.description}</div>;
	}

	let hasPendingStatuses =
		projectInitializationStatus.googleChatSpaceStatus === 'INITIALIZING';
	let hasErrorStatuses =
		projectInitializationStatus.googleChatSpaceStatus === 'ERROR';
	for (const fileStatus of Object.values(
		projectInitializationFileStatuses
	) as any[]) {
		if (
			fileStatus.status === 'INITIALIZING' ||
			fileStatus.status === 'CREATED'
		) {
			hasPendingStatuses = true;
		}
		if (fileStatus.status === 'UPDATE_FAILED') {
			hasErrorStatuses = true;
		}
	}

	const keys = Object.keys(projectInitializationFileStatuses);
	const longestKeyLength = Math.max(...keys.map((key) => key.length));

	return (
		<FileStatusContainer>
			<CenterContainer>
				<FileStatus>
					<Key>ChatSpace</Key>
					<Value>
						{getFileStatus(
							projectInitializationStatus.googleChatSpaceStatus
						)}
					</Value>
				</FileStatus>
				{keys.map((key) => (
					<>
						<Divider style={{ marginTop: 8, marginBottom: 8 }} />
						<FileStatus key={key}>
							<Key>{key}</Key>
							<Value>
								{getFileStatus(
									projectInitializationFileStatuses[key]
										.status
								)}
							</Value>
						</FileStatus>
					</>
				))}
			</CenterContainer>
		</FileStatusContainer>
	);
};

const FileStatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	flex-grow: 1;
`;

const CenterContainer = styled.div`
	display: flex;
	justify-content: left;
	flex-direction: column;
	width: 440px;
	max-width: 100%;
	overflow: scroll;
	padding-left: 15px;
	padding-right: 15px;
`;

const getFileStatus = (status: string) => {
	switch (status) {
		case 'CREATED':
			return 'Created';
		case 'INITIALIZING':
			return 'Initializing';
		case 'ERROR':
			return <RedExclamationIcon />;
		case 'CREATION_ERROR':
			return <RedExclamationIcon />;
		case 'UPDATE_ERROR':
			return <RedExclamationIcon />;
		case 'SUCCESS':
			return <GreenCheckIcon />;
		default:
			return status;
	}
};
