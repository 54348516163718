import React, { useEffect, useMemo, useState } from 'react';
import ScreenContainer from '../common/layout/ScreenContainer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { fetchCompany } from '../../data/thunks/companyThunk';
import ScreenBusySpinner from '../common/ScreenBusySpinner';
import { connectQuickBooksRedirect } from '../../services/user-service';
import Button from '../common/buttons/Button';
import dayjs from 'dayjs';
import Table from '../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import sortBy from 'lodash/sortBy';
import GreenCheck from '../common/GreenCheckIcon';
import RedExclamationIcon from '../common/RedExclamationIcon';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { QbInfoSheet } from '../../data/models/company';
import QbInfoSheetModal from './QbInfoSheetModal';

export default function ConfigurationScreen() {
	const dispatch = useDispatch<AppDispatch>();
	useEffect(() => {
		dispatch(fetchCompany());
	}, []);

	const {
		company: { quickBooksTimeConnection },
	} = useSelector((state: RootState) => state.companyState);

	const startOAuth = async () => {
		const redirectUrl = await connectQuickBooksRedirect();
		window.open(redirectUrl);
	};

	return (
		<>
			<ScreenBusySpinner />
			<ScreenContainer>
				<Button onClick={startOAuth}>Connect to QuickBooks</Button>
				<QuickBooksTimeStatus
					quickBooksTimeConnection={quickBooksTimeConnection}
				/>
				<QbInfoSheetsTable
					quickBooksTimeConnection={quickBooksTimeConnection}
				/>
			</ScreenContainer>
		</>
	);
}

const QbInfoSheetsTable = (props) => {
	const { quickBooksTimeConnection } = props;

	const [qbInfoSheetModal, setQbInfoSheetModal] = useState<
		QbInfoSheet | false
	>(false);

	const columns: GridColDef[] = useMemo(() => {
		return [
			// {
			// 	field: 'sheetName',
			// 	headerName: 'Sheet Name',
			// 	minWidth: 450,
			// 	disableColumnMenu: true,
			// 	sortable: false,
			// 	renderCell: (params: any) => <div>{params.value}</div>,
			// },
			{
				field: 'qbProjectName',
				headerName: 'QB Project Name',
				minWidth: 350,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => <div>{params.value}</div>,
			},
			{
				field: 'status',
				headerName: 'Status',
				headerAlign: 'center',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: false,
				align: 'center',
				display: 'flex',
				renderCell: (params: any) =>
					params.value ? <RedExclamationIcon /> : <GreenCheck />,
			},
			{
				field: 'lastUpdated',
				headerName: 'Last Updated',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					params.value
						? dayjs(params.value).format('MMM D, h:mm A')
						: 'NA',
			},
			{
				field: 'actions',
				headerName: '',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				align: 'center',
				renderCell: (params: any) => (
					<IconButton
						color="primary"
						onClick={() => setQbInfoSheetModal(params.value)}
					>
						<EditIcon />
					</IconButton>
				),
			},
		];
	}, []);

	const rows: any[] = useMemo(() => {
		return sortBy(
			quickBooksTimeConnection.qbInfoSheets.map((sheet) => ({
				// ...sheet.value,
				id: sheet.value.sheetName + Math.random(),
				googleFileId: sheet.value.googleFileId,
				lastUpdated: sheet.value.lastUpdated,
				qbProjectName: sheet.value.qbProjectName,
				status: sheet.value.failure,
				actions: sheet.value,
			})),
			'qbProjectName'
		).reverse();
	}, [quickBooksTimeConnection?.qbInfoSheets]);

	if (!quickBooksTimeConnection?.qbInfoSheets) {
		return <></>;
	}

	return (
		<>
			<ScreenBusySpinner />
			<QbInfoSheetModal
				qbInfoSheet={qbInfoSheetModal}
				open={!!qbInfoSheetModal}
				handleClose={() => setQbInfoSheetModal(false)}
			/>
			<Table
				columns={columns}
				rows={rows}
				page={0}
				pageSize={10}
				rowCount={quickBooksTimeConnection?.qbInfoSheets?.length}
				sortModel={[
					{ field: 'status', sort: 'desc' },
					{ field: 'qbProjectName', sort: 'desc' },
				]}
				onSortModelChange={() => {}}
				onPaginationModelChange={() => {}}
				hideFooterPagination
				// toolbar={<Toolbar />}
				// loading={!quickBooksTimeConnection.qbInfoSheets.length}
			/>
		</>
	);
};

const QuickBooksTimeStatus = (props) => {
	const { quickBooksTimeConnection } = props;
	if (!quickBooksTimeConnection) {
		return <p>Not connected to QuickBooks Time</p>;
	}
	return (
		<>
			<p>QuickBooks Time Status</p>
			<p>
				Last successful update:{' '}
				{dayjs(
					quickBooksTimeConnection.lastFetchedRecordsFromDb
				).format('MMM D, YYYY, h:mm A')}
			</p>
			<p>
				Status:{' '}
				{quickBooksTimeConnection.failure
					? `The most recent update failed on ${dayjs(
							quickBooksTimeConnection.lastFailureDate
					  ).format('MMM D, YYYY, h:mm A')}`
					: 'The last update was successful'}
			</p>
		</>
	);
};
