import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ClientDetailsScreen from './details/ClientDetailsScreen';
import ClientsListScreen from './ClientsListScreen';

export default function ClientsRouter() {
	return (
		<Routes>
			<Route path={'/'} element={<ClientsListScreen />} />
			<Route path={':clientId'} element={<ClientDetailsScreen />} />
		</Routes>
	);
}
