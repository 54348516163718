import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import * as ProjectService from '../../services/project-service';
import { showAlert } from '../stores/alertStore';
import {
	asyncCallDone,
	asyncCallError,
	asyncCallStart,
	fetchProjectsSuccess,
	refreshProjectSuccess,
} from '../stores/projectStore';
import { ProjectsFilter } from '../models/project';

export const fetchProject =
	(id: string): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await ProjectService.fetchProject(id);
			dispatch(refreshProjectSuccess(response));
		} catch (error) {
			// dispatch(asyncCallError(error));
			// dispatch(
			// 	showAlert({
			// 		message: 'An error occurred while fetching the Project.',
			// 		severity: 'error',
			// 	})
			// );
		}
		dispatch(asyncCallDone());
	};

export const fetchProjects =
	(filter: ProjectsFilter): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await ProjectService.fetchProjects(filter);
			dispatch(fetchProjectsSuccess(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Projects.',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};
