import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Input from './form/Input';
import { useState } from 'react';
import { styled } from 'styled-components';

interface TableToolbarProps {
	searchText?: string;
	handleTextSearch?: (value: string) => void;
}

const TableToolbar: React.FC<TableToolbarProps> = ({
	searchText = '',
	handleTextSearch,
}) => {
	const [localSearchText, setLocalSearchText] = useState(searchText);

	const handleSearchChange = (event) => {
		setLocalSearchText(event.target.value);
	};

	const handleSearch = () => {
		handleTextSearch && handleTextSearch(localSearchText);
	};
	return (
		<GridToolbarContainer>
			<GridToolbarExport />
			<Box sx={{ flexGrow: 1 }} />
			{handleTextSearch && (
				<Box
					sx={{
						width: 300,
						marginLeft: 2,
						marginRight: 2,
						marginTop: 1,
					}}
				>
					<Input
						fullWidth
						placeholder="Search..."
						value={localSearchText}
						onChange={handleSearchChange}
						variant={'standard'}
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								handleSearch();
							}
						}}
						InputProps={{
							startAdornment: (
								<SearchIcon style={{ marginRight: 8 }} />
							),
						}}
					/>
				</Box>
			)}
		</GridToolbarContainer>
	);
};

export default TableToolbar;
