import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from './layout/Paper';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			style={{ flex: 1 }}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

interface VerticalTabsProps {
	tabs: { label: string; content: React.ReactNode }[];
	value: number;
	setValue: (value: number) => void;
	showTabs?: boolean;
	initialTab?: number;
}

const StyledTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		right: 'auto', // reset the right value
		left: 0, // set the left value
	},
});

const VerticalTabs: React.FC<VerticalTabsProps> = ({
	tabs,
	value,
	setValue,
	showTabs = true,
	initialTab = 0,
}) => {
	// const [value, setValue] = React.useState(initialTab);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box
			sx={{
				flexGrow: 1,
				bgcolor: 'transparent',
				display: 'flex',
			}}
		>
			<StyledTabs
				orientation="vertical"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{
					borderRight: 1,
					borderWidth: 0,
					marginRight: 10,
				}}
			>
				{tabs.map((tab, index) => (
					<Tab
						key={index}
						label={tab.label}
						style={{ alignItems: 'flex-start' }}
						{...a11yProps(index)}
					/>
				))}
			</StyledTabs>
			{showTabs &&
				tabs.map((tab, index) => (
					<TabPanel key={index} value={value} index={index}>
						{tab.content}
					</TabPanel>
				))}
		</Box>
	);
};

export default VerticalTabs;
