import React from 'react';
import { isMobile } from 'react-device-detect';
import Button from '../buttons/Button';
import styled from 'styled-components';
import Fade from '@mui/material/Fade';

interface SaveCancelButtonsProps {
	handleSave: () => void;
	handleCancel: () => void;
	saveText?: string;
	cancelText?: string;
	hidden?: boolean;
	saveDisabled?: boolean;
	cancelDisabled?: boolean;
	floatingButtons?: boolean;
	disableAnimations?: boolean;
	modalDisplay?: boolean;
	cardDisplay?: boolean;
}

export default function SaveCancelButtons(props: SaveCancelButtonsProps) {
	const {
		handleSave,
		saveText = 'SAVE',
		cancelText = 'CANCEL',
		handleCancel,
		hidden,
		saveDisabled,
		cancelDisabled,
		floatingButtons,
		disableAnimations = false,
		cardDisplay,
	} = props;

	if (cardDisplay && !isMobile) {
		return (
			<Fade
				in={!hidden}
				appear={!disableAnimations}
				exit={!disableAnimations}
			>
				<CardContainer>
					<Button
						onClick={handleCancel}
						variant={'outlined'}
						disabled={cancelDisabled}
						style={{
							width: 150,
						}}
					>
						{cancelText}
					</Button>
					<Button
						onClick={handleSave}
						disabled={saveDisabled}
						style={{
							width: 150,
							marginLeft: 8,
						}}
					>
						{saveText}
					</Button>
				</CardContainer>
			</Fade>
		);
	}

	if (floatingButtons && isMobile) {
		return (
			<FloatingButtonsContainer hidden={hidden}>
				<StyledButton
					onClick={handleCancel}
					color={'warning'}
					disabled={cancelDisabled}
				>
					Cancel
				</StyledButton>
				<StyledButton onClick={handleSave} disabled={saveDisabled}>
					{saveText}
				</StyledButton>
			</FloatingButtonsContainer>
		);
	}

	if (isMobile && !hidden) {
		return (
			<MobileContainer>
				<StyledButton
					onClick={handleCancel}
					color={'warning'}
					disabled={cancelDisabled}
					variant={'text'}
				>
					Cancel
				</StyledButton>
				<StyledButton onClick={handleSave} disabled={saveDisabled}>
					{saveText}
				</StyledButton>
			</MobileContainer>
		);
	}

	return (
		<Fade
			in={!hidden}
			appear={!disableAnimations}
			exit={!disableAnimations}
		>
			<Container>
				<OutlinedButton
					onClick={handleCancel}
					variant={'outlined'}
					disabled={cancelDisabled}
				>
					Cancel
				</OutlinedButton>
				<SaveButton onClick={handleSave} disabled={saveDisabled}>
					{saveText}
				</SaveButton>
			</Container>
		</Fade>
	);
}

const Container = styled.div`
	display: inline-flex;
	flex: 1;
	align-items: end;
	justify-content: flex-end;
`;

const CardContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
`;

const MobileContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	flex: 1;
	height: 100px;
`;

const FloatingButtonsContainer = styled.div<{ hidden?: boolean }>`
	display: ${(props) => (props.hidden ? 'none' : 'flex')};
	position: fixed;
	text-align: center;
	left: 0;
	bottom: 0;
	zindex: 9;
	flex: 1;
	width: 100%;
`;

const StyledButton = (props) => (
	<Button
		style={{
			fontSize: '16px',
			fontWeight: 'bold',
			borderRadius: '0',
			paddingTop: '10px',
			paddingBottom: '10px',
		}}
		{...props}
	/>
);

const OutlinedButton = (props) => (
	<Button
		style={{
			width: '150px',
		}}
		{...props}
	/>
);

const SaveButton = (props) => (
	<Button
		style={{
			width: '150px',
			marginLeft: '15px',
		}}
		{...props}
	/>
);
