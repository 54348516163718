import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export function getTheme(mode: 'light' | 'dark') {
	let theme = createTheme({
		palette: {
			mode: mode,
			primary: {
				main: '#1f97f4',
			},
			secondary: {
				main: '#19857b',
			},
			success: {
				main: mode === 'light' ? '#31AD37' : '#67e56d',
				dark: mode === 'light' ? '#E9FDEA' : '#2a562b',
			},
			error: {
				main: '#f44336',
			},
			warning: {
				main: '#ff9800',
			},
			info: {
				main: mode === 'light' ? '#2196f3' : '#53A7F0',
			},
			neutral: {
				main: '#E0E0E0',
			},
			background: {
				default: mode === 'light' ? '#FAFAFA' : '#191b1d',
				paper: mode === 'light' ? '#fff' : '#1f2125',
			},
			text: {
				primary: mode === 'light' ? '#1E1E1E' : '#ffffff',
				secondary: mode === 'light' ? '#757575' : '#bbbbbb',
				// @ts-ignore
				label: mode === 'light' ? '#00000099' : '#9A9CA5',
				divider: mode === 'light' ? '#00000099' : '#9A9CA5',
				subheader: mode === 'light' ? '#00000099' : '#ffffff',
			},
		},
		typography: {
			fontFamily: 'Arial, sans-serif',
			h1: {
				fontSize: '2.2rem',
				fontWeight: 500,
			},
			// Add more typography styles here
		},
		spacing: 8, // The base spacing unit
		borderRadius: 5, // The global border radius
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 960,
				lg: 1280,
				xl: 1920,
			},
		},
		components: {
			// Component style overrides go here
			MuiButton: {
				styleOverrides: {
					root: {
						// Define button styles here
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: mode === 'light' ? '#fff' : '#1f2125',
					},
				},
			},
			// Add more component overrides here
		},
		// Define additional custom properties here if needed
	});

	theme = responsiveFontSizes(theme); // Enhance the theme for responsive fonts

	return theme;
}
