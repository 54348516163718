import React, { useEffect } from 'react';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { HeaderTitle } from '../common/layout/PageHeader';
import RadioButtons from '../common/buttons/RadioButtons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { Controller, useForm } from 'react-hook-form';
import { styled } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import SaveCancelButtons from '../common/buttons/SaveCancelButtons';
import { updateSelf } from '../../data/thunks/userThunk';

export default function SettingsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.authState);

	const { handleSubmit, control, reset, formState, watch } = useForm({
		defaultValues: {
			preferences: user?.preferences || {
				theme: 'dark',
			},
		},
		mode: 'onBlur',
	});

	const { dirtyFields } = formState;

	const resetForm = () => {
		reset({
			preferences: user?.preferences || {
				theme: 'dark',
			},
		});
	};
	useEffect(() => {
		resetForm();
	}, [user?.preferences]);

	const handleSave = (data: any) => {
		dispatch(updateSelf({ preferences: data.preferences }));
	};

	return (
		<div>
			<HeaderTitle>Settings</HeaderTitle>
			<Paper>
				<SaveCancelButtons
					handleSave={handleSubmit(handleSave)}
					handleCancel={() => resetForm()}
					hidden={isEmpty(dirtyFields)}
					cardDisplay
				/>
				<PaperHeader>My Preferences</PaperHeader>
				<ThemeContainer>
					<Controller
						name={'preferences.theme'}
						control={control}
						rules={{ required: true }}
						render={({ field }) => {
							return (
								<RadioButtons
									{...field}
									label="Theme"
									name="preferences.theme"
									options={[
										{ value: 'dark', label: 'Dark Mode' },
										{ value: 'light', label: 'Light Mode' },
										{
											value: 'system',
											label: 'System Preference',
										},
									]}
									onChange={field.onChange}
								/>
							);
						}}
					/>
				</ThemeContainer>
			</Paper>
		</div>
	);
}

const ThemeContainer = styled.div`
	padding: 15px;
`;
