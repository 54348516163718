import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const HeaderContainer = styled.div`
	flex: 1;
	display: flex;
`;

export const HeaderTitle = (props) => {
	const { children, sx = {} } = props;

	return (
		<Typography variant={'h4'} sx={{ marginBottom: 2, ...sx }}>
			{children}
		</Typography>
	);
};

export const HeaderButton = styled.div`
	flex: 1;
	text-align: right;
`;

export const SubHeader = styled.span`
	font-size: 0.5 rem;
	color: ${({ theme }) => theme.palette.text.subheader};
	margin-bottom: 5px;
	margin-top: 5px;
`;
