import React, { useEffect, useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { formatNamespaceForProject } from '../../../utils/project-utils';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import {
	fetchNextProjectNumber,
	updateProjectName,
} from '../../../services/project-service';
import { set } from 'lodash';
import BusySpinner from '../../common/BusySpinner';
import { Project } from '../../../data/models/project';
import Input from '../../common/form/Input';
import ModalHeader from '../../common/ModalHeader';
import Dialog, {
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '../../common/Dialog';
import Button from '../../common/buttons/Button';
import { useDispatch } from 'react-redux';
import {
	asyncCallDone,
	asyncCallStart,
	refreshProjectSuccess,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
// import { DialogTitle } from '@mui/material';

interface ProjectNameChangeModalProps {
	open: boolean;
	// handleSave: () => void;
	handleClose: () => void;
	project: Project;
}
export default function ProjectNameChangeModal(
	props: ProjectNameChangeModalProps
) {
	const { open, handleClose, project } = props;

	const dispatch = useDispatch();
	const [newProjectName, setNewProjectName] = useState<string>('');
	const [conformationModalOpen, setConformationModalOpen] = useState(false);

	const handleUpdateProjectName = async () => {
		try {
			handleClose();
			setNewProjectName('');
			dispatch(asyncCallStart());
			const updatedProject = await updateProjectName(
				project.id,
				newProjectName
			);
			dispatch(refreshProjectSuccess(updatedProject));
			dispatch(
				showAlert({
					message:
						'We recieved your request! Please allow a few minutes for the project to be updated.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred creating the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ModalHeader>Update Project Namespace</ModalHeader>
			<Label>Number</Label>
			<Info>{project.number}</Info>
			<Label>Client</Label>
			<Info>{project.client.name}</Info>
			<Label>Existing Name</Label>
			<Info>{project.name}</Info>
			<InputContainer>
				<Input
					value={newProjectName}
					onChange={(e) => setNewProjectName(e.target.value)}
					label={'New Project Name'}
					id="new-project-name"
				/>
			</InputContainer>
			<SaveCancelButtons
				handleSave={() => setConformationModalOpen(true)}
				handleCancel={handleClose}
				saveText={'Review'}
				saveDisabled={!newProjectName}
			/>
			<Dialog
				open={conformationModalOpen}
				handleClose={() => setConformationModalOpen(false)}
			>
				<DialogTitle>Confirm Project Name Change</DialogTitle>
				<DialogContent>
					<Label>Current Namespace</Label>
					<Info>{project.namespace}</Info>
					<Label>New Namespace</Label>
					<Info>
						{formatNamespaceForProject(
							project.number,
							project.client.name,
							newProjectName
						)}
					</Info>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setConformationModalOpen(false)}
						variant={'text'}
					>
						Cancel
					</Button>
					<Button onClick={handleUpdateProjectName}>Save</Button>
				</DialogActions>
			</Dialog>
		</Modal>
	);
}

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const InputContainer = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(4)};
	margin-top: ${(props) => props.theme.spacing(2)};
`;
