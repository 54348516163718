import { Paper as MuiPaper } from '@mui/material';
import { styled } from 'styled-components';

interface PaperProps {
	variant?: 'elevation' | 'outlined';
	style?: any;
	children: React.ReactNode;
	className?: string;
}
const Paper = (props: PaperProps) => {
	const { children, variant = 'outlined', style = {}, className } = props;
	return (
		<MuiPaper
			sx={(theme) => ({
				borderColor: theme.palette.divider,
				fontSize: '0.875rem',
				position: 'relative',
			})}
			style={{ ...style }}
			variant={variant}
			className={className}
		>
			{children}
		</MuiPaper>
	);
};

export default Paper;

export const PaperHeader = styled.h2`
	color: ${(props) => props.theme.palette.text.label};
	margin-left: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
`;
