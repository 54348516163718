import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MUICheckbox from '@mui/material/Checkbox';
import { Box } from '../layout/Content';

export default function Checkbox(props) {
	const { items } = props;

	if (!items?.length) return <></>;

	return (
		<FormGroup>
			{items.map((item) => {
				if (item.children) {
					return (
						<div key={item.id}>
							<FormControlLabel
								label={item.name}
								control={
									<MUICheckbox
										checked={item.checked}
										indeterminate={item.indeterminate}
										onChange={item.handleOnChange}
									/>
								}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									ml: 3,
								}}
							>
								{item.children.map((child) => (
									<FormControlLabel
										key={child.id}
										sx={{ width: 'auto' }}
										control={
											<MUICheckbox
												checked={child.checked}
												onChange={child.handleOnChange}
											/>
										}
										label={child.name}
									/>
								))}
							</Box>
						</div>
					);
				}
				return (
					<FormControlLabel
						key={item.id}
						sx={{ width: 'auto' }}
						control={
							<MUICheckbox
								checked={item.checked}
								onChange={item.handleOnChange}
							/>
						}
						label={item.name}
					/>
				);
			})}
		</FormGroup>
	);
}
