import React from 'react';
import { styled } from 'styled-components';
import Modal from '../common/Modal';
import Label from '../common/Label';
import { QbInfoSheet } from '../../data/models/company';
import dayjs from 'dayjs';
import Link from '../common/Link';
import Button from '../common/buttons/Button';
import { AppDispatch } from '../../data/store';
import { useDispatch } from 'react-redux';
import { updateQBInfoSheet } from '../../data/thunks/companyThunk';

interface QbInfoSheetModalProps {
	open: boolean;
	handleClose: () => void;
	qbInfoSheet: QbInfoSheet | false;
}
export default function QbInfoSheetModal(props: QbInfoSheetModalProps) {
	const { open, handleClose, qbInfoSheet } = props;

	const dispatch = useDispatch<AppDispatch>();

	const handleUpdateQBInfoSheet = async () => {
		if (!qbInfoSheet) {
			return;
		}
		dispatch(updateQBInfoSheet(qbInfoSheet?.googleFileId));
		handleClose();
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			{open && qbInfoSheet ? (
				<div>
					<Label>Quickbooks Project</Label>
					<Info>{qbInfoSheet.qbProjectName}</Info>
					<Label>Last Updated</Label>
					<Info>
						{dayjs(qbInfoSheet.lastUpdated).format(
							'MMM D, YYYY, h:mm A'
						)}
					</Info>
					<Label>Spreadsheet Link</Label>
					<Info>
						<Link
							onClick={() =>
								window.open(
									`https://docs.google.com/spreadsheets/d/${qbInfoSheet.googleFileId}/edit?gid=1143509523`,
									'_blank'
								)
							}
						>
							Sheet
						</Link>
					</Info>
					<SyncButtonContainer>
						<Button
							onClick={handleUpdateQBInfoSheet}
							variant={'outlined'}
						>
							Sync Sheet
						</Button>
					</SyncButtonContainer>
					{/* <Label>Workspace Members</Label> */}
					{/* <SaveCancelButtons
                    handleSave={handleSave}
                    handleCancel={handleClose}
                    saveText={'Create'}
                /> */}
				</div>
			) : (
				<></>
			)}
		</Modal>
	);
}

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const SyncButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: ${(props) => props.theme.spacing(2)};
`;
