import React from 'react';
import {
	Dialog as MuiDialog,
	DialogTitle as MuiDialogTitle,
	DialogContent as MuiDialogContent,
	DialogContentText as MuiDialogContentText,
	DialogActions as MuiDialogActions,
} from '@mui/material';

interface DialogProps {
	open: boolean;
	handleClose: () => void;
	children: React.ReactNode;
}

const Dialog: React.FC<DialogProps> = ({ open, handleClose, children }) => {
	return (
		<MuiDialog open={open} onClose={handleClose}>
			{children}
		</MuiDialog>
	);
};

export default Dialog;
export const DialogTitle = MuiDialogTitle;
export const DialogContent = MuiDialogContent;
export const DialogContentText = MuiDialogContentText;
export const DialogActions = MuiDialogActions;
