import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectsListScreen from './ProjectsListScreen';
import ProjectDetailsScreen from './details/ProjectDetailsScreen';

export default function ProjectsRouter() {
	return (
		<Routes>
			<Route path={'/'} element={<ProjectsListScreen />} />
			<Route path={':projectId'} element={<ProjectDetailsScreen />} />
		</Routes>
	);
}
