import React, { useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import { unarchiveProject } from '../../../services/project-service';
import { Project } from '../../../data/models/project';
import ModalHeader from '../../common/ModalHeader';
import Dialog, {
	DialogActions,
	DialogContent,
	DialogTitle,
} from '../../common/Dialog';
import Button from '../../common/buttons/Button';
import { useDispatch } from 'react-redux';
import {
	asyncCallDone,
	asyncCallStart,
	refreshProjectSuccess,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';

interface ProjectUnarchiveModalProps {
	open: boolean;
	handleClose: () => void;
	project: Project;
}
export default function ProjectUnarchiveModal(
	props: ProjectUnarchiveModalProps
) {
	const { open, handleClose, project } = props;

	const dispatch = useDispatch();
	const [conformationModalOpen, setConformationModalOpen] = useState(false);

	const handleArchive = async () => {
		try {
			handleClose();
			setConformationModalOpen(false);
			dispatch(asyncCallStart());
			const updatedProject = await unarchiveProject(project.id);
			dispatch(refreshProjectSuccess(updatedProject));
			dispatch(
				showAlert({
					message: 'Successfully unarchived the project.',
					severity: 'success',
				})
			);
		} catch (error) {
			dispatch(
				showAlert({
					message: 'An error occurred unarchiving the project.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<Modal open={open} handleClose={handleClose}>
			<ModalHeader>Unarchive Project</ModalHeader>
			<Label>Name</Label>
			<Info>{project.namespace}</Info>
			<Label>Client</Label>
			<Info>{project.client.name}</Info>
			<SaveCancelContainer>
				<SaveCancelButtons
					handleSave={() => setConformationModalOpen(true)}
					handleCancel={handleClose}
					saveText={'Unarchive'}
				/>
			</SaveCancelContainer>
			<Dialog
				open={conformationModalOpen}
				handleClose={() => setConformationModalOpen(false)}
			>
				<DialogTitle>Confirm Project Unarchive</DialogTitle>
				<DialogContent>
					<Label>Name</Label>
					<Info>{project.namespace}</Info>
					<div>Are you sure you want to unachive this project?</div>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setConformationModalOpen(false)}
						variant={'text'}
					>
						Cancel
					</Button>
					<Button onClick={handleArchive}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</Modal>
	);
}

const Info = styled.div`
	margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const SaveCancelContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing(4)};
`;
