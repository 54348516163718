import React from 'react';
import { styled } from 'styled-components';

export default function CenterContainer({
	children,
}: {
	children: React.ReactNode;
}) {
	return <StyledContainer>{children}</StyledContainer>;
}

const StyledContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;
