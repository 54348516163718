import React, { useEffect, useState } from 'react';
import Modal from '../../common/Modal';
import SaveCancelButtons from '../../common/buttons/SaveCancelButtons';
import { formatNamespaceForProject } from '../../../utils/project-utils';
import Label from '../../common/Label';
import { styled } from 'styled-components';
import {
	createProject,
	fetchNextProjectNumber,
} from '../../../services/project-service';
import BusySpinner from '../../common/BusySpinner';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
	asyncCallDone,
	asyncCallStart,
} from '../../../data/stores/projectStore';
import { showAlert } from '../../../data/stores/alertStore';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import Input from '../../common/form/Input';
import Autocomplete from '../../common/form/AutoComplete';
import { AsyncStage } from '../../../data/reduxCommon';
import { createClient } from '../../../services/client-service';
import { ClientDTO } from '../../../data/models/client';
import ModalHeader from '../../common/ModalHeader';

interface ClientCreationModalProps {
	open: boolean;
	handleClose: () => void;
}
export default function ClientCreationModal(props: ClientCreationModalProps) {
	const { open, handleClose } = props;

	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { stage } = useSelector((state: RootState) => state.clientState);

	const { handleSubmit, control, reset, formState } = useForm({
		defaultValues: {
			name: '',
		},
		mode: 'onSubmit',
	});
	const { errors } = formState;

	const resetForm = () => {
		reset();
	};

	const handleCancel = () => {
		resetForm();
		handleClose();
	};

	const handleCreateClient = async (data: ClientDTO) => {
		try {
			dispatch(asyncCallStart());
			const createdClient = await createClient(data);
			navigate(`/clients/${createdClient.id}`);
			resetForm();
		} catch (error) {
			console.error('error', error);
			dispatch(
				showAlert({
					message: 'An error occurred creating the client.',
					severity: 'error',
				})
			);
		} finally {
			dispatch(asyncCallDone());
		}
	};

	return (
		<>
			<ScreenBusySpinner />
			<Modal open={open} handleClose={handleClose}>
				{open ? (
					<FormContainer>
						<ModalHeader>Create New Client</ModalHeader>
						<InputContainer>
							<Controller
								name={'name'}
								control={control}
								rules={{ required: 'Name is required' }}
								render={({ field }) => (
									<Input
										{...field}
										label={'Client Name'}
										id="clientName"
										onChange={(e) => {
											const newValue = e.target.value;
											const regex = /[^A-Za-z0-9 -]/g;
											if (regex.test(newValue)) return;
											field.onChange(newValue);
										}}
										error={errors.name}
										helperText={errors.name?.message}
									/>
								)}
							/>
							<SaveCancelButtons
								handleSave={handleSubmit(handleCreateClient)}
								handleCancel={handleCancel}
								saveDisabled={stage === AsyncStage.Busy}
								cancelDisabled={stage === AsyncStage.Busy}
								saveText={'Create'}
							/>
						</InputContainer>
					</FormContainer>
				) : (
					<></>
				)}
			</Modal>
		</>
	);
}

const Namespace = styled.h2`
	margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const LinkContainer = styled.div`
	font-size: 12px;
	text-align: left;
	margin-top: 5px;
`;

const FormContainer = styled.div`
	text-align: center;
`;

const InputContainer = styled.div`
	max-width: 450px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	margin-right: auto;
	margin-left: auto;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
`;
