import React, { useState } from 'react';
import ClientsTable from './ClientsTable';
import {
	HeaderButton,
	HeaderContainer,
	HeaderTitle,
} from '../common/layout/PageHeader';
import Button from '../common/buttons/Button';
import { useNavigate } from 'react-router-dom';
import ClientCreationModal from './creation/ClientCreationModal';

export default function ClientsListScreen() {
	const navigate = useNavigate();

	const [clientCreationModalOpen, setClientCreationModalOpen] =
		useState(false);

	return (
		<div>
			<ClientCreationModal
				open={clientCreationModalOpen}
				handleClose={() => setClientCreationModalOpen(false)}
			/>
			<HeaderContainer>
				<HeaderTitle>Clients</HeaderTitle>
				<HeaderButton>
					<Button
						onClick={() => setClientCreationModalOpen(true)}
						variant={'outlined'}
					>
						Create Client
					</Button>
				</HeaderButton>
			</HeaderContainer>
			<ClientsTable />
		</div>
	);
}
