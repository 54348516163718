import GooleDriveIcon from '../images/google-drive.png';
import GoogleMailIcon from '../images/communication.png';
import GoogleCalendarIcon from '../images/google-calendar.png';
import GoogleChatIcon from '../images/google-chat.png';
import GoogleDocsIcon from '../images/google-docs.png';
import GoogleSheetsIcon from '../images/google-sheets.png';
import GoogleSlidesIcon from '../images/google-slides.png';
import GoogleFormsIcon from '../images/google-forms.png';
import GenericLinkIcon from '../images/generic-link.png';
import AdobeIcon from '../images/adobe.png';
import DiscordIcon from '../images/discord.png';
import SlackIcon from '../images/slack.png';
import DropBoxIcon from '../images/dropbox.png';
import FigmaIcon from '../images/figma.png';
import NotionIcon from '../images/notion.png';
import YoutubeIcon from '../images/youtube.png';

export const quickLinkIcons = {
	googleDrive: {
		icon: GooleDriveIcon,
		alt: 'Google Drive icon',
	},
	googleMail: {
		icon: GoogleMailIcon,
		alt: 'Google Mail icon',
	},
	googleChat: {
		icon: GoogleChatIcon,
		alt: 'Google Chat icon',
	},
	googleDocs: {
		icon: GoogleDocsIcon,
		alt: 'Google Docs icon',
	},
	googleCalendar: {
		icon: GoogleCalendarIcon,
		alt: 'Google Calendar icon',
	},
	googleSheets: {
		icon: GoogleSheetsIcon,
		alt: 'Google Sheets icon',
	},
	googleSlides: {
		icon: GoogleSlidesIcon,
		alt: 'Google Slides icon',
	},
	googleFormsIcon: {
		icon: GoogleFormsIcon,
		alt: 'Google Forms icon',
	},
	discordIcon: {
		icon: DiscordIcon,
		alt: 'Discord icon',
	},
	slackIcon: {
		icon: SlackIcon,
		alt: 'Slack icon',
	},
	adobe: {
		icon: AdobeIcon,
		alt: 'Adobe icon',
	},
	dropbox: {
		icon: DropBoxIcon,
		alt: 'Dropbox icon',
	},
	figma: {
		icon: FigmaIcon,
		alt: 'Figma icon',
	},
	notion: {
		icon: NotionIcon,
		alt: 'Notion icon',
	},
	youtube: {
		icon: YoutubeIcon,
		alt: 'Youtube icon',
	},
	genericLink: {
		icon: GenericLinkIcon,
		alt: 'Generic link icon',
	},
};

export const getQuickLinkIconFromUrl = (url: string) => {
	// order matters for specificity
	const iconName = {
		drive: 'googleDrive',
		chat: 'googleChat',
		mail: 'googleMail',
		calendar: 'googleCalendar',
		docs: 'googleDocs',
		sheets: 'googleSheets',
		slides: 'googleSlides',
		forms: 'googleFormsIcon',
		discord: 'discordIcon',
		slack: 'slackIcon',
		adobe: 'adobe',
		dropbox: 'dropbox',
		figma: 'figma',
		notion: 'notion',
		youtube: 'youtube',
	};

	let iconKey = Object.keys(iconName).find((key) => url.includes(key));
	let icon = iconKey ? iconName[iconKey] : 'genericLink';

	return quickLinkIcons[icon];
};
