import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
	asyncCallStart,
	asyncCallError,
	asyncCallDone,
	loginSuccess,
	updateSelfSuccess,
} from '../stores/authStore';
import * as UserService from '../../services/user-service';
import {
	UpdateSelfDTO,
	UserDTO,
	UserUpdateDTO,
	UsersFilter,
} from '../models/user';
import { showAlert } from '../stores/alertStore';
import { fetchUsersSuccess, updateUserSuccess } from '../stores/companyStore';

export const createUsersFromGoogleUsers =
	(users: UserDTO[]): AppThunk =>
	async (dispatch: Dispatch) => {
		let failedUsers = 0;
		dispatch(asyncCallStart());
		try {
			for (const user of users) {
				await UserService.createUser(user).catch((error) => {
					console.error(error);
					failedUsers++;
				});
			}
			const response = await UserService.fetchUsers();
			dispatch(fetchUsersSuccess(response));
		} catch (error) {
			console.error(error);
			dispatch(asyncCallError(error));
		}
		dispatch(asyncCallDone());
		const message =
			failedUsers > 0
				? `${
						users.length - failedUsers
				  } users created successfully and ${failedUsers} failed.`
				: 'All users were successfully created.';
		dispatch(
			showAlert({
				message,
				severity: 'success',
			})
		);
	};

export const updateUser =
	(user: UserUpdateDTO): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const updatedUser = await UserService.updateUser(user);
			dispatch(updateUserSuccess(updatedUser));
		} catch (error) {
			console.error(error);
			dispatch(asyncCallError(error));
		}
		dispatch(asyncCallDone());
		dispatch(
			showAlert({
				message: 'Successfully updated user.',
				severity: 'success',
			})
		);
	};

export const fetchSelf = (): AppThunk => async (dispatch: Dispatch) => {
	dispatch(asyncCallStart());
	try {
		const user = await UserService.fetchSelf();
		dispatch(updateSelfSuccess({ user }));
	} catch (error) {
		console.error(error);
		dispatch(asyncCallError(error));
	}
	dispatch(asyncCallDone());
};

export const updateSelf =
	(user: UpdateSelfDTO): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const updatedUser = await UserService.updateSelf(user);
			dispatch(updateSelfSuccess({ user: updatedUser }));
		} catch (error) {
			console.error(error);
			dispatch(asyncCallError(error));
		}
		dispatch(asyncCallDone());
		dispatch(
			showAlert({
				message: 'Successfully updated your account.',
				severity: 'success',
			})
		);
	};

export const fetchUsers =
	(filter: UsersFilter): AppThunk =>
	async (dispatch: Dispatch) => {
		dispatch(asyncCallStart());
		try {
			const response = await UserService.fetchUsers(filter);
			dispatch(fetchUsersSuccess(response));
		} catch (error) {
			dispatch(asyncCallError(error));
			dispatch(
				showAlert({
					message: 'An error occurred while fetching the Company',
					severity: 'error',
				})
			);
		}
		dispatch(asyncCallDone());
	};

export const login =
	// (googleCredentials: {
	// 	idToken: string;
	// 	accessToken: string;
	// 	refreshToken: string;
	// }): AppThunk =>


		(credential: string): AppThunk =>
		async (dispatch: Dispatch) => {
			dispatch(asyncCallStart());
			try {
				const response = await UserService.login(credential);
				dispatch(
					loginSuccess({
						user: response.user,
						// credential,
						astroToken: response.astroToken,
					})
				);
			} catch (error) {
				console.error(error);
				dispatch(asyncCallError(error));
				// dispatch(
				//     showAlert({
				//         message: 'An error occurred while fetching your report.',
				//         variant: 'error',
				//     }),
				// );
			}
			dispatch(asyncCallDone());
		};
