import { useState, useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { fetchUsers } from '../services/user-service';
import { User } from '../data/models/user';

const MAX_USERS = 999;

function useUsersList() {
	const [list, setList] = useState<User[]>([]);

	useEffect(() => {
		const fetchUsersForDropdown = async () => {
			const users = await fetchUsers({
				searchText: '',
				order: 'created',
				direction: 'DESC',
				page: 0,
				take: MAX_USERS,
				where: {},
			});
			setList(users.list);
		};
		fetchUsersForDropdown();
	}, []);

	const dropdownOptions = useMemo(() => {
		return sortBy(
			list.map((user: any) => ({
				id: user.id,
				display: `${user.firstName} ${user.lastName}`,
			})),
			['display']
		);
	}, [list]);

	const dropdownOptionsFullData = useMemo(() => {
		return sortBy(
			list.map((user: User) => ({
				...user,
				id: user.id,
				display: `${user.firstName} ${user.lastName}`,
			})),
			['display']
		);
	}, [list]);

	return {
		list,
		dropdownOptions,
		dropdownOptionsFullData,
	};
}

export default useUsersList;
