import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import store from './data/store';
import ThemeProvider from './components/ThemeProvider';

function App() {
	return (
		<ReduxProvider store={store}>
			<ThemeProvider />
		</ReduxProvider>
	);
}

export default App;
