import React, { useEffect, useMemo, useState } from 'react';
import { Project, ProjectsFilter } from '../../data/models/project';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../common/Table';
import { GridToolbarContainer } from '@mui/x-data-grid';
import Link from '../common/Link';
import ProjectStatusDisplay from '../projects/ProjectStatusDisplay';
import { fetchProjects } from '../../services/project-service';
import { PaperHeader } from '../common/layout/Paper';
import { useNavigate } from 'react-router-dom';

export function ProjectsRecentlyCreated() {
	const navigate = useNavigate();

	const [projects, setProjects] = useState<{
		list: Project[];
		count: number;
		initialized: boolean;
	}>({ list: [], count: 0, initialized: false });

	useEffect(() => {
		const initializeProjects = async () => {
			const projectsFilter: ProjectsFilter = {
				searchText: '',
				page: 0,
				take: 5,
				order: 'created',
				direction: 'desc',
				where: {},
			};
			const response = await fetchProjects(projectsFilter);
			setProjects({ ...response, initialized: true });
		};
		initializeProjects();
	}, [fetchProjects]);

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'namespace',
				headerName: 'Namespace',
				minWidth: 325,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<Link
							onClick={() => navigate(`/projects/${params.id}`)}
						>
							{params.value}
						</Link>
					);
				},
			},
			{
				field: 'status',
				headerName: 'Status',
				minWidth: 150,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => (
					<ProjectStatusDisplay status={params.value} />
				),
			},
			{
				field: 'createdBy',
				headerName: 'Created By',
				minWidth: 200,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => <div>{params.value.name}</div>,
			},
			{
				field: 'created',
				headerName: 'Created On',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, h:mm A'),
			},
		];
	}, []);

	const rows: any[] = useMemo(() => {
		return projects.list.map((project) => ({
			id: project.id,
			namespace: project.namespace,
			status: project.status,
			created: project.created,
			createdBy: project.createdBy,
		}));
	}, [projects.list]);

	return (
		<Table
			columns={columns}
			rows={rows}
			page={0}
			pageSize={10}
			rowCount={projects.count}
			sortModel={[{ field: 'created', sort: 'desc' }]}
			onSortModelChange={() => {}}
			onPaginationModelChange={() => {}}
			hideFooterPagination
			toolbar={<RecentlyCreatedToolbar />}
			loading={!projects.initialized}
		/>
	);
}

const RecentlyCreatedToolbar = () => {
	return (
		<GridToolbarContainer>
			<PaperHeader>Recently Created Projects</PaperHeader>
		</GridToolbarContainer>
	);
};

export function ProjectsRecentlyArchived() {
	const navigate = useNavigate();

	const [projects, setProjects] = useState<{
		list: Project[];
		count: number;
		initialized: boolean;
	}>({ list: [], count: 0, initialized: false });

	useEffect(() => {
		const initializeProjects = async () => {
			const projectsFilter: ProjectsFilter = {
				searchText: '',
				page: 0,
				take: 5,
				order: 'updated',
				direction: 'desc',
				where: {
					status: ['ARCHIVED'],
				},
			};
			const response = await fetchProjects(projectsFilter);
			setProjects({ ...response, initialized: true });
		};
		initializeProjects();
	}, [fetchProjects]);

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'namespace',
				headerName: 'Namespace',
				minWidth: 325,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => {
					return (
						<Link
							onClick={() => navigate(`/projects/${params.id}`)}
						>
							{params.value}
						</Link>
					);
				},
			},
			{
				field: 'status',
				headerName: 'Status',
				minWidth: 150,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) => (
					<ProjectStatusDisplay status={params.value} />
				),
			},
			{
				field: 'archivedOn',
				headerName: 'Archived On',
				minWidth: 140,
				disableColumnMenu: true,
				sortable: false,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, h:mm A'),
			},
		];
	}, []);

	const rows: any[] = useMemo(() => {
		return projects.list.map((project) => ({
			id: project.id,
			namespace: project.namespace,
			status: project.status,
			archivedOn: project.archivedOn,
		}));
	}, [projects.list]);

	return (
		<Table
			columns={columns}
			rows={rows}
			page={0}
			pageSize={10}
			rowCount={projects.count}
			sortModel={[{ field: 'updated', sort: 'desc' }]}
			onSortModelChange={() => {}}
			onPaginationModelChange={() => {}}
			hideFooterPagination
			toolbar={<RecentlyArchivedToolbar />}
			loading={!projects.initialized}
		/>
	);
}

const RecentlyArchivedToolbar = () => {
	return (
		<GridToolbarContainer>
			<PaperHeader>Recently Archived Projects</PaperHeader>
		</GridToolbarContainer>
	);
};
