import React from 'react';
import Paper, { PaperHeader } from '../common/layout/Paper';
import { styled } from 'styled-components';
import Link from '../common/Link';

const KeyLinks = () => {
	return (
		<Paper style={{ height: '100%' }}>
			<PaperHeader>Key Links</PaperHeader>
			<KeyLinksContainer>
				<Link onClick={() => alert('Yo get me these')}>
					Getting Started with AstroAI
				</Link>
				<Link onClick={() => alert('Yo get me these')}>
					Guidelines and Best Practices
				</Link>
				<Link onClick={() => alert('Yo get me these')}>
					User Management
				</Link>
			</KeyLinksContainer>
		</Paper>
	);
};

export default KeyLinks;

const KeyLinksContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 10px;
`;
