import * as React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

interface AccordionItem {
	title: string;
	content: string;
	actions?: {
		label: string;
		onClick: () => void;
	}[];
	defaultExpanded?: boolean;
}

interface AccordionProps {
	items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
	return (
		<div>
			{items.map((item, index) => (
				<MuiAccordion
					key={index}
					defaultExpanded={item.defaultExpanded}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel${index + 1}-content`}
						id={`panel${index + 1}-header`}
					>
						{item.title}
					</AccordionSummary>
					<AccordionDetails>{item.content}</AccordionDetails>
					{item.actions && (
						<AccordionActions>
							{item.actions.map((action, actionIndex) => (
								<Button
									key={actionIndex}
									onClick={action.onClick}
								>
									{action.label}
								</Button>
							))}
						</AccordionActions>
					)}
				</MuiAccordion>
			))}
		</div>
	);
};

export default Accordion;
