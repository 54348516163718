import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootState } from '../../data/store';
import { hideAlert } from '../../data/stores/alertStore';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertBanner() {
	const dispatch = useDispatch();
	const { open, message, severity } = useSelector(
		(state: RootState) => state.alertState
	);

	const handleClose = (
		event?: Event | React.SyntheticEvent<any, Event>,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(hideAlert());
	};

	const autoHideDuration = severity === 'success' ? 2000 : 6000;

	return (
		<Snackbar
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert
				onClose={handleClose}
				severity={severity}
				sx={{ width: '100%', color: 'white' }}
			>
				{message}
			</Alert>
		</Snackbar>
	);
}
