import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertState {
	stage: '';
	open: boolean;
	message: string;
	severity: 'error' | 'warning' | 'info' | 'success';
}

const initialState: AlertState = {
	stage: '',
	open: false,
	message: '',
	severity: 'info',
};

export const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		showAlert: (
			state,
			action: PayloadAction<{
				message: string;
				severity: AlertState['severity'];
			}>
		) => {
			state.open = true;
			state.message = action.payload.message;
			state.severity = action.payload.severity;
		},
		hideAlert: (state) => {
			state.open = false;
			// state.message = '';
			// state.severity = 'info';
		},
	},
});

export const { showAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
