import React from 'react';
import Button from '@mui/material/Button';

interface ButtonProps {
	children: any;
	onClick: () => void;
	color?:
		| 'inherit'
		| 'primary'
		| 'secondary'
		| 'success'
		| 'error'
		| 'info'
		| 'warning';
	style?: any;
	disabled?: boolean;
	sz?: 'small' | 'medium' | 'large';
	variant?: 'contained' | 'text' | 'outlined';
	startIcon?: any;
}
const AstroButton = (props: ButtonProps) => {
	const {
		children,
		onClick,
		color = 'primary',
		variant = 'contained',
		style,
		disabled,
		sz = 'medium',
		startIcon,
	} = props;

	return (
		<Button
			onClick={onClick}
			color={color}
			variant={variant}
			style={style}
			size={sz}
			startIcon={startIcon}
			disabled={disabled}
		>
			{children}
		</Button>
	);
};

export default AstroButton;
