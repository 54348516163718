export const formatClientNameForNamespace = (clientName: string) => {
	return clientName.toUpperCase().replace(/\s/g, '');
};

export const formatProjectNameForNamespace = (projectName: string) => {
	return projectName.replace(/\s/g, '');
};

// IMPORTANT! This function needs to match the format of the namespace on the backend
export const formatNamespaceForProject = (
	number,
	clientName: string,
	projectName: string
) => {
	return `${number}_${formatClientNameForNamespace(
		clientName
	)}_${formatProjectNameForNamespace(projectName)}`;
};
