import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '../../data/store';
import { Spinner } from './BusySpinner';

export default function ScreenBusySpinner() {
	const appState = useSelector((state: RootState) => state);

	if (!appState) return <></>;

	let busy = false;
	Object.entries(appState).forEach(([key, value]) => {
		if (value.stage === 'Busy') busy = true;
	});

	if (busy) {
		return (
			<OuterContainer>
				<InnerContainer>
					<Spinner />
				</InnerContainer>
			</OuterContainer>
		);
	}
	return <></>;
}

const OuterContainer = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
`;

const InnerContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 200px;
	margin: auto;
	text-align: center;
`;
