import React, { useEffect, useState } from 'react';
import ScreenContainer from '../../common/layout/ScreenContainer';
import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../data/store';
import { fetchClient } from '../../../data/thunks/clientThunk';
import ScreenBusySpinner from '../../common/ScreenBusySpinner';
import BusySpinner from '../../common/BusySpinner';
import Button from '../../common/buttons/Button';
import dayjs from 'dayjs';
import { isAdmin } from '../../../utils/auth-utils';
import ProjectCreationModal from '../../projects/creation/ProjectCreationModal';

export default function ClientDetailsScreen() {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const match = useMatch('clients/:clientId/*');
	const clientId = match?.params?.clientId;

	const { client } = useSelector((state: RootState) => state.clientState);

	const [projectCreationModal, setProjectCreationModal] = useState(false);

	useEffect(() => {
		if (clientId && clientId !== client.id) {
			dispatch(fetchClient(clientId));
		}
	}, [clientId, client.id, dispatch]);

	if (clientId !== client.id) {
		return <BusySpinner />;
	}

	return (
		<>
			<ScreenBusySpinner />
			<ProjectCreationModal
				open={!!projectCreationModal}
				clientId={clientId}
				handleClose={() => setProjectCreationModal(false)}
			/>
			<ScreenContainer>
				<h2>{client.name} Client</h2>
				<div>
					Created on: {dayjs(client.created).format('MMM D, YYYY')}
				</div>
				<div>Created by: {client.createdBy?.name}</div>
				{isAdmin() && (
					<Button onClick={() => setProjectCreationModal(true)}>
						Create Project
					</Button>
				)}
			</ScreenContainer>
		</>
	);
}
