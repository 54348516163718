import MuiLink from '@mui/material/Link';
import { styled } from 'styled-components';

const Link = (props) => {
	const { children, href, onClick, underline = 'hover', style = {} } = props;

	if (onClick) {
		return (
			<StyledLink
				onClick={onClick}
				underline={underline}
				style={{ ...style }}
			>
				{children}
			</StyledLink>
		);
	}

	return (
		<StyledLink href={href} underline={underline} style={{ ...style }}>
			{children}
		</StyledLink>
	);
};

export default Link;

const StyledLink = styled(MuiLink)`
	cursor: pointer;
`;
