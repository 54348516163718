import React, { useEffect, useMemo } from 'react';
import usePagination from '../../hooks/usePaginationStore';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../data/store';
import { fetchClients } from '../../data/thunks/clientThunk';
import { updateClientsFilter } from '../../data/stores/clientStore';
import dayjs from 'dayjs';
import Table from '../common/Table';
import { GridColDef } from '@mui/x-data-grid';
import Link from '../common/Link';
import { useNavigate } from 'react-router-dom';

export default function ClientsTable() {
	const dispatch: AppDispatch = useDispatch();
	const navigate = useNavigate();

	const { clients } = useSelector((state: RootState) => state.clientState);

	const {
		searchText,
		setSearchText,
		page,
		setPage,
		pageSize,
		setPageSize,
		sortModel,
		setSortModel,
		where,
		setWhere,
	} = usePagination('clientState', 'clients', updateClientsFilter);

	useEffect(() => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	}, []);

	const handleTextSearch = (newSearchText: string) => {
		setSearchText(newSearchText);
		setPage(0);
		const filter = {
			searchText: newSearchText,
			order: 'created',
			direction: 'DESC',
			page: 0,
			take: pageSize,
		};
		dispatch(fetchClients(filter));
	};

	const handleSortOnChange = (order) => {
		setSortModel(order);
		const filter = {
			searchText,
			order: order[0]?.field,
			direction: order[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	};

	const handleOnPageChange = (paginationModel, scrollToTop = false) => {
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: paginationModel.page,
			take: pageSize,
			where,
		};
		setPage(paginationModel.page);
		dispatch(fetchClients(filter));
		if (scrollToTop) {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const handleOnPageSizeChange = (pageSize) => {
		setPageSize(pageSize);
		const filter = {
			searchText,
			order: sortModel[0]?.field,
			direction: sortModel[0]?.sort,
			page: page,
			take: pageSize,
			where,
		};
		dispatch(fetchClients(filter));
	};

	const handleUserOnEdit = (id: string) => {
		// const user = users.list.find((u) => u.id === id);
		// if (user) {
		// 	setEditModal(user);
		// }
	};

	// const ExpandableCell = (props) => {
	// 	console.log('props', props);
	// 	const [isOpen, setIsOpen] = React.useState(false);

	// 	const handleToggle = () => {
	// 		setIsOpen(!isOpen);
	// 	};

	// 	return (
	// 		<div>
	// 			<button onClick={handleToggle}>
	// 				{isOpen ? 'Hide Projects' : 'Show Projects'}
	// 			</button>
	// 			{isOpen && (
	// 				<div
	// 					style={{
	// 						position: 'absolute',
	// 						backgroundColor: 'black',
	// 						zIndex: 1,
	// 					}}
	// 				>
	// 					<ul>
	// 						{props.projects.map((project: Partial<Project>) => (
	// 							<li key={project.id}>{project.namespace}</li>
	// 						))}
	// 					</ul>
	// 				</div>
	// 			)}
	// 		</div>
	// 	);
	// };

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => {
					return (
						<Link
							onClick={() =>
								navigate(`/clients/${params.row.id}`)
							}
						>
							{params.value}
						</Link>
					);
				},
			},
			{
				field: 'projects',
				headerName: 'Projects',
				minWidth: 200,
				disableColumnMenu: true,
				renderCell: (params: any) => <div>{params.value}</div>,
			},
			{
				field: 'created',
				headerName: 'Created',
				minWidth: 120,
				disableColumnMenu: true,
				renderCell: (params: any) =>
					dayjs(params.value).format('MMM D, YYYY'),
			},
			// { id: 'name', label: 'Name' },
			// // { id: 'projectCount', label: 'Project Count' },
			// {
			// 	id: 'created',
			// 	label: 'Created',
			// 	renderCell: (row) => dayjs(row.created).format('MMM D, YYYY'),
			// },
			// {
			// 	id: 'actions',
			// 	label: '',
			// 	renderCell: (row) => (
			// 		<EditIconContainer onClick={() => handleUserOnEdit(row.id)}>
			// 			<EditIcon color={'primary'} fontSize={'small'} />
			// 		</EditIconContainer>
			// 	),
			// },
		];
	}, [handleUserOnEdit]);

	const rows: any[] = useMemo(() => {
		return clients.list.map((client) => ({
			id: client.id,
			name: client.name,
			projects: client.projects.length,
			created: client.created,
		}));
	}, [clients.list]);

	return (
		<div>
			<Table
				columns={columns}
				rows={rows}
				page={page}
				pageSize={pageSize}
				rowCount={clients.count}
				sortModel={sortModel}
				onSortModelChange={handleSortOnChange}
				searchText={searchText}
				handleTextSearch={handleTextSearch}
				onPaginationModelChange={handleOnPageChange}
				loading={!clients.initialized}
			/>
		</div>
	);
}
