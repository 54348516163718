import React from 'react';
import Button from '@mui/material/Button';
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components';
import { ListItem } from '@mui/material';

export default function Menu(props: any) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const {
		title,
		menuItems,
		color,
		variant,
		dropDownIcon,
		disabled = false,
		iconButton = false,
		iconButtonStyle = {},
		buttonStyle = {},
		containerStyle = {},
		anchorOrigin = {
			vertical: 'bottom',
			horizontal: 'left',
		},
		alternativeIcon,
	} = props;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleOnClick = (onClick: any) => {
		setAnchorEl(null);
		return onClick();
	};

	return (
		<div style={{ ...containerStyle }}>
			{iconButton ? (
				<StyledIconButton
					aria-label="more"
					id="long-button"
					color={color || 'primary'}
					style={{ ...iconButtonStyle }}
					aria-controls={Boolean(anchorEl) ? 'long-menu' : undefined}
					aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
					aria-haspopup="true"
					onClick={handleClick}
				>
					{alternativeIcon ? alternativeIcon() : <MoreVertIcon />}
				</StyledIconButton>
			) : (
				<Button
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
					disabled={disabled}
					style={{ ...buttonStyle }}
					color={color || 'primary'}
					variant={variant || 'text'}
					sx={{ textTransform: 'none' }}
				>
					{title}
					{alternativeIcon && alternativeIcon()}
					<div
						style={{
							display: dropDownIcon ? '' : 'none',
							paddingTop: 5,
						}}
					>
						<ArrowDropDownIcon />
					</div>
				</Button>
			)}
			<MuiMenu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				anchorOrigin={anchorOrigin}
				onClose={() => handleOnClick(() => {})} // This is necessary to prevent crash in development mode
			>
				{menuItems.map((item: any, index: number) => {
					if (item.display === 'DIVIDER')
						return <Divider key={index} />;
					if (!item.onClick)
						return <ListItem key={index}>{item.display}</ListItem>;
					return (
						<MenuItem
							key={index}
							disabled={item.disabled}
							onClick={() => handleOnClick(item.onClick)}
						>
							{item.icon ? item.icon : ''}
							{item.display}
						</MenuItem>
					);
				})}
			</MuiMenu>
		</div>
	);
}

const StyledIconButton = styled(IconButton)`
	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary};
	}
`;
