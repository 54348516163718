import React, { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

interface TextInputProps {
	id?: string;
	value?: string | number;
	onChange?: (arg0: any) => void;
	label?: string;
	onChangeText?: (value: string) => void;
	fontSize?: string;
	multiline?: boolean;
	maxRows?: number;
	defaultValue?: string | number;
	rules?: any;
	ref?: any;
	placeholder?: string;
	secureTextEntry?: {
		textVisible: boolean;
		toggle: () => void;
	};
	icon?: React.ReactElement;
	onBlur?: any;
	style?: any;
	variant?: 'filled' | 'outlined' | 'standard';
	fullWidth?: boolean;
	disabled?: boolean;
	type?: string;
	required?: boolean;
	helperText?: string;
	error?: any;
	inputProps?: any;
	InputProps?: any;
	autoFocus?: boolean;
	onClick?: (event: any) => void;
	onKeyDown?: (event: any) => void;
	endAdornment?: any;
	size?: 'small' | 'medium';
}
const TextInput = forwardRef((props: TextInputProps, ref) => {
	const {
		id,
		value,
		onChange,
		label,
		fontSize,
		multiline,
		maxRows = 1,
		defaultValue,
		placeholder = '',
		secureTextEntry = false,
		icon,
		onBlur,
		style,
		variant = 'outlined',
		fullWidth = true,
		disabled = false,
		type,
		required = false,
		helperText,
		error,
		inputProps,
		InputProps,
		autoFocus,
		onClick,
		onKeyDown,
		size = 'small',
		...otherProps
	} = props;

	return (
		<TextField
			{...otherProps}
			id={id}
			placeholder={placeholder}
			style={style}
			inputRef={ref}
			value={value}
			onChange={onChange}
			label={label}
			variant={variant}
			fullWidth={fullWidth}
			multiline={multiline}
			disabled={disabled}
			type={type}
			required={required}
			onBlur={onBlur}
			helperText={helperText}
			error={error}
			inputProps={inputProps}
			InputProps={InputProps}
			autoFocus={autoFocus}
			onClick={onClick}
			maxRows={maxRows}
			onKeyDown={onKeyDown}
			size={size}
			InputLabelProps={{ shrink: true }}
		/>
	);
});

export default TextInput;
