import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function usePagination(store, key, actionEmitter) {
	const dispatch = useDispatch();
	const {
		[key]: {
			filter: {
				searchText,
				page,
				pageSize,
				sortModel,
				onlyShowUnused,
				folders,
				where,
			},
		},
	} = useSelector((state: any) => state[store]);

	const setSearchText = useCallback((newSearchText) => {
		dispatch(actionEmitter({ searchText: newSearchText }));
	}, []);

	const setSortModel = useCallback((newSortModel) => {
		dispatch(actionEmitter({ sortModel: newSortModel }));
	}, []);

	const setPage = useCallback((newPage) => {
		dispatch(actionEmitter({ page: newPage }));
	}, []);

	const setPageSize = useCallback((newPageSize) => {
		dispatch(actionEmitter({ pageSize: newPageSize }));
	}, []);

	const setFolders = useCallback((newFolders) => {
		dispatch(actionEmitter({ folders: newFolders }));
	}, []);

	const setWhere = useCallback((newWhereFilter) => {
		dispatch(actionEmitter({ whereFilter: newWhereFilter }));
	}, []);

	const setOnlyShowUnused = useCallback((newValue) => {
		dispatch(actionEmitter({ onlyShowUnused: newValue }));
	}, []);

	const usersFilter = {
		searchText,
		order: sortModel[0]?.field,
		direction: sortModel[0]?.sort,
		page: page,
		take: pageSize,
		where,
	};

	const clientsFilter = {
		searchText,
		order: sortModel[0]?.field,
		direction: sortModel[0]?.sort,
		page: page,
		take: pageSize,
		where,
	};

	return {
		searchText,
		setSearchText,
		sortModel,
		setSortModel,
		page,
		setPage,
		pageSize,
		setPageSize,
		onlyShowUnused,
		setOnlyShowUnused,
		folders,
		setFolders,
		where,
		setWhere,
		usersFilter,
		clientsFilter,
	};
}
